'use strict'

angular.module('cb.prospect-service', [])
    .factory('ProspectService', ["ApiService", function (ApiService) {

        var prospectService = {
            getProspectsByBatchId: function (data) {
                try {
                    var url = '/webapi/api/v1/prospect/getProspectsByBatchId/' + data;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            getProspectDetail: function (data) {
                try {
                    var url = '/webapi/api/v1/prospect/viewProspect/' + data;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            getProspectsForFirstMailing: function (data) {
				try {
					var url = '/webapi/api/v1/prospect/getProspectsForFirstMailing';;
                    return ApiService.promisePost(url,data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			GetConservatoryType: function (data, batchId,mailing) {
				try {
					var url = '/webapi/api/v1/prospect/getConservatoryTypes/' + data + "/" + batchId + "/" + mailing;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getProspectsForFollowupMailing: function (data) {
                try {
					var url = '/webapi/api/v1/prospect/getProspectsForFollowupMailing';
                    return ApiService.promisePost(url,data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            saveProspect: function (data) {
                try {
                    var url = '/webapi/api/v1/prospect/updateProspect';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},

			saveDuplicate: function (data) {
				try {
					var url = '/webapi/api/v1/prospect/updateDuplicate';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

            convertToLead: function (data) {
                try {
                    var url = '/webapi/api/v1/prospect/convertToLead';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            getDuplicates: function (Id) {
                try {
                    var url = '/webapi/api/v1/prospect/GetDuplicates/' + Id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetDuplicatesofprospect: function (Id) {
                try {
                    var url = '/webapi/api/v1/prospect/GetDuplicatesofprospect/' + Id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            SetAsADuplication: function (Id) {
                try {
                    var url = '/webapi/api/v1/prospect/SetAsADuplication/' + Id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            SetAsNonDuplication: function (Id) {
                try {
                    var url = '/webapi/api/v1/prospect/SetAsNonDuplication/' + Id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            reconcileBuilders: function (data) {
                try {
                    var url = '/webapi/api/v1/prospect/ReconcileAgent';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            }
        }


        return prospectService;
    }]);
